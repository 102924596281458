import { applyVueInReact, connectVuex } from "vuereact-combined";
import React, { Component } from "react";
import axios from "@/axios";
import LoadingSpinner from "@/components/LoadingSpinner";
import Payment from "../../components/payment/Payment";
class InternetBillLifenet extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);

  constructor() {
    super();
    this.state = {
      loading: true,
      reports: "",
      username: "",
      message: "",
      serviceId: "",
      newPlanSelected: false,
      selectedPackage: {},
      selectedPlan: {},
      amount: 0,
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  validationBeforePayment = () => {
    if (
      this.state.newPlanSelected &&
      !Object.keys(this.state.selectedPackage).length > 0
    ) {
      this.setState({ message: "Please select the Package." });
      return false;
    }
    if (!Object.keys(this.state.selectedPlan).length > 0) {
      this.setState({ message: "Please select the Plan." });
      return false;
    }
    return true;
  };

  handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "selectedPackage" || name === "selectedPlan") {
      value = value ? JSON.parse(value) : "";
    }
    this.setState({ [name]: value, message: "" }, () => {
      if (name === "selectedPackage") {
        this.setState({ selectedPlan: {} });
      }
      if (name === "selectedPlan") {
        this.setState({ amount: this.state.selectedPlan.PlanAmount || 0 });
      }
    });
  };

  checkLifenetAccount = async (event) => {
    event.preventDefault();
    if (!this.state.username) {
      this.setState({ message: "Please enter your Lifenet username." });
      return;
    }
    this.setState({ loading: true });
    const data = await axios.post("/api/v2/services/lifenet/checkAccount", {
      LifeNetUserName: this.state.username,
    });
    this.setState({ loading: false, message: "" });
    if (data.data && data.data.response) {
      if (data.data.response.Code !== "000") {
        this.setState({ message: data.data.response.Message });
      } else {
        this.setState({ reports: data.data.response });
      }
    }
  };

  render() {
    return (
      <div className="container">
        <div className="wallet-form-box card">
          <div className="card-body">
            <h5 className="card-title">Lifenet Payment.</h5>
            <a href="/" className="btn btn-close">
              <i className="fa fa-times"></i>
            </a>
            {this.state.message && (
              <div className="form-group fade-animation">
                <div className="show-message">{this.state.message}</div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7">
                {this.state.reports ? (
                  <div className="fade-animation">
                    <div className="mt-3">
                      <div className="font-weight-bold">Lifenet UserName</div>
                      <div>{this.state.reports.LifeNetUserName}</div>
                    </div>
                    <div className="mt-3">
                      <div className="font-weight-bold">Customer Name</div>
                      <div>{this.state.reports.CustomerName}</div>
                    </div>
                    <div className="mt-3">
                      <div className="font-weight-bold">Active Package</div>
                      <div>{this.state.reports.ActivePackageName}</div>
                    </div>
                    <div className="mt-3">
                      {/* ACCORDING TO TEST PORTAL DAYS REMAINING == Additional4 */}
                      <div className="font-weight-bold"> Days Remaining</div>
                      <div>{this.state.reports.Additional4} Days</div>
                    </div>
                    <div className="mt-3 ml-4">
                      <div className="custom-checkbox">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          value={this.state.newPlanSelected}
                          onChange={() => {
                            this.setState((prevState) => {
                              return {
                                newPlanSelected: !prevState.newPlanSelected,
                                selectedPackage: {},
                                selectedPlan: {},
                                message: "",
                              };
                            });
                          }}
                          id="selectedPackage"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="selectedPackage"
                        >
                          <div className="font-weight-bold">
                            Select new Plan
                          </div>
                        </label>
                      </div>
                    </div>

                    {this.state.newPlanSelected ? (
                      <>
                        {/* PACKAGE SELECTION SECTION */}
                        <div className="mt-3">
                          <div className="mt-3">
                            <div className="font-weight-bold">
                              Select Package
                            </div>
                            <select
                              className="custom-select"
                              name="selectedPackage"
                              onChange={this.handleChange}
                            >
                              <option value="">-- Select Package --</option>
                              {this.state.reports.Packages.map((pkg) => {
                                return (
                                  <option
                                    key={pkg.PackageId}
                                    value={JSON.stringify(pkg)}
                                  >
                                    {pkg.PackageName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        {/* PLAN SELECTION SECTION */}

                        <div className="mt-3">
                          <div className="font-weight-bold">Select Plan</div>
                          <select
                            className="custom-select"
                            name="selectedPlan"
                            onChange={this.handleChange}
                          >
                            <option value="">-- Select Plan --</option>
                            {Object.keys(this.state.selectedPackage).length >
                              0 &&
                              this.state.selectedPackage.Duration.map(
                                (plan) => {
                                  return (
                                    <option
                                      key={plan.PlanId}
                                      value={JSON.stringify(plan)}
                                    >
                                      {plan.PlanName}
                                    </option>
                                  );
                                }
                              )}
                          </select>
                        </div>
                      </>
                    ) : (
                      // RENEWAL PLAN SELECTION SECTION
                      <div className="mt-3">
                        <div className="font-weight-bold">
                          Select Plan for Renewal
                        </div>
                        <select
                          className="custom-select"
                          name="selectedPlan"
                          onChange={this.handleChange}
                        >
                          <option value="">-- Select Plan --</option>
                          {this.state.reports.Plans.map((plan) => {
                            return (
                              <option
                                key={plan.PlanId}
                                value={JSON.stringify(plan)}
                              >
                                {plan.PlanName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    {Object.keys(this.state.selectedPlan).length > 0 && (
                      <div className="mt-3">
                        <div className="font-weight-bold">Amount</div>
                        <div>Rs. {this.state.amount.toFixed(2)}</div>
                      </div>
                    )}
                    <div className="fade-animation">
                      <Payment
                        paymentUri="api/v4/lifenet/payment"
                        paymentPayload={{
                          UserName: this.state.username,
                          PlanId: this.state.selectedPlan.PlanId,
                          PackageId:
                            Object.keys(this.state.selectedPackage).length > 0
                              ? this.state.selectedPackage.PackageId
                              : this.state.reports.Additional5,
                          PackageName:
                            Object.keys(this.state.selectedPackage).length > 0
                              ? this.state.selectedPackage.PackageName
                              : this.state.reports.ActivePackageName,
                          Amount: this.state.amount,
                          CustomerName: this.state.reports.CustomerName,
                        }}
                        setMessage={(message) => {
                          this.setState({ message });
                        }}
                        validationBeforePayment={this.validationBeforePayment}
                        paymentPreviewDetail={{
                          "Customer Name": this.state.reports.CustomerName,
                          "Total Amount to Pay": "Rs. " + this.state.amount,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <form>
                    <div className="form-group">
                      <label>
                        Username <i className="fa fa-asterisk"></i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Type Username and press enter."
                        value={this.state.username}
                        name="username"
                        onChange={this.handleChange}
                      />
                      <button
                        className="btn btn-primary mt-3"
                        onClick={this.checkLifenetAccount}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <this.LoadingSpinnerComp loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}
export default InternetBillLifenet;
